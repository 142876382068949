import React from "react";
import Slider, { Settings } from "react-slick";
import BROpacity from "./BROpacity";
import NextArrow from "./NextArrow";
import PreviousArrow from "./PreviousArrow";
import { BannerContainer } from "./Banner.styled";
import line_img from "../../assets/HomePage/Line-29.png";

// import { contentTagNameById } from "constants/content";
// import { handleClickOnHomePageContent } from "modules/utils";
type BannerProp = {
  bannerList: any[];
  hideTag?: boolean;
  btnTxt?: string;
  make?: string;
  model?: string;
};
const Banner: React.FC<BannerProp> = ({
  bannerList,
  hideTag = false,
  btnTxt,
}) => {
  const settings: Settings = {
    customPaging: (i: number) => <p>{` `}</p>,
    dots: true,
    dotsClass: "slideshow-slider-dots",
    lazyLoad: "progressive",
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
  };
  const handleClickOnHomePageContent = (sitem: any) => {
    console.log("sitem", sitem);
    // window.open(item.textLink, target);
  };
  return (
    <BannerContainer>
      <Slider {...settings}>
        {bannerList?.map(
          (item: any = {}, index: any) => (
            // !(!item.contentId && !item.heading) && (
            <div
              key={`banner_${item?.id || item?.displayOrder}`}
              onClick={() => handleClickOnHomePageContent(item)}
            >
              <figure>
                <img
                  // onError={addDefaultImage}
                  src={item.image}
                  alt="Shop Banner"
                />
                {/* <BROpacity opacity={item.opacity} /> */}
                <figcaption>
                  <div className="home-banner-image-text-sec">
                    {/* <span className="position-relative carosel-cat-name-new carosel-cat-name font-weight-bold pl-2">
                      <img src={line_img} />{" "}
                      {index === 0 ? "Auction" : "Article"}
                    </span>

                    <h3 className="mb-2 main-sub-page-title heroLineClamp">
                      {item?.title}
                    </h3>
                    <p className="carosel-sub-sub-text main-sub-page-sub-title">
                      {item.subTitle}
                    </p> */}
                    {/* <div className="carosel-btn-sec">
                      <a href={item?.textLink} target="_blank">
                        <button>
                          {index === 0 ? "GO TO AUCTION" : "READ"}
                        </button>
                      </a>
                    </div> */}
                  </div>
                  {/* {!hideTag &&
                    item.contentTypeId > 0 &&
                    item.contentTypeId <= 10 && (
                      <small>
                        {"contentTagNameById[item.contentTypeId]" ||
                          item.contentTypeId}
                      </small>
                    )}
                  <span>{item.heading || item.title}</span>
                  {btnTxt && (
                    <button onClick={() => handleClickOnHomePageContent(item)}>
                      {btnTxt}
                    </button>
                  )} */}
                </figcaption>
              </figure>
            </div>
          )
          // )
        )}
      </Slider>
    </BannerContainer>
  );
};

export default Banner;
